import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Instagram from "../components/instagram"
import LeafletMap from "../components/leafletMap"
import Footer from "../components/footer"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <section className="hero">
        <Img
          fluid={data.coffeeShop.childImageSharp.fluid}
          className="hero-image"
        />
      </section>
      <section className="container">
        <div className="about-grid">
          <h2>Natürliche Eleganz und einen Hauch Glamour</h2>
          <p>
            Meine Leidenschaft ist, mit Formen und Farbe zu spielen, was sich in
            meiner Arbeit und in meinem persönlichen Stil widerspiegelt.
            <br />
            <br />
            Eine kleine Bibliothek und speziell ausgewählte Musik runden das
            Erlebnis in meinem Haar Atelier ab.
          </p>
        </div>
        <div className="gallery-grid">
          <Img
            fluid={data.latte.childImageSharp.fluid}
            className="gallery-img1"
          />
          <Img
            fluid={data.coffeeBags.childImageSharp.fluid}
            className="gallery-img2"
          />
          <Img
            fluid={data.coffeePortrait.childImageSharp.fluid}
            className="gallery-img3"
          />
        </div>
      </section>
      {/*       <div className="parallax"></div> */}
      <section className="container">
        {/*  <Menu /> */}
        <Instagram />
      </section>
      {typeof window !== "undefined" && (
        <LeafletMap
          position={[47.37059, 8.51793]} // Your Coordinates
          zoom={18} // Zoom Level
          markerText={
            "Rotschopf Haaratelier, Birmensdorferstrasse 174 8003 Zürich"
          } // Icon text
        />
      )}
      <Footer />
    </Layout>
  )
}

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coffeeShop: file(relativePath: { eq: "Rotschopf3_1.jpg" }) {
      ...fluidImage
    }
    coffeePortrait: file(relativePath: { eq: "laden2_.jpg" }) {
      ...fluidImage
    }
    latte: file(relativePath: { eq: "Rotschopf1.jpg" }) {
      ...fluidImage
    }
    coffeeBags: file(relativePath: { eq: "Rotschopf2.jpg" }) {
      ...fluidImage
    }
  }
`

export default IndexPage

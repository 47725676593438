import React from "react"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <footer>
      <section>
        <div className="address">
          <h3>LOCATION</h3>
          <span>Rotschopf Haar Atelier</span>
          <span>Birmensdorferstrasse 174</span>
          <span>8003 Zürich</span>
        </div>
        <div className="hours">
          <h3>ÖFFNUNGSZEITEN</h3>
          <span>Montag Geschlossen</span>
          <span>Dienstag-Freitag 1000 - 2000</span>
          <span> Samstag 1000 - 1700 </span>
        </div>
        <div className="contact">
          <h3>KONTAKT</h3>
          <span>Mischa Leutwiler</span>
          <span>076 581 37 05</span>
          <a href="mailto:mischa@rotschopf.ch">mischa@rotschopf.ch</a>
        </div>
      </section>
      <p>
        Built by
        <a
          href="https://www.4arge.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          4arge
        </a>
        <br />
        {/*  <Link to="Impressum">Impressum</Link> */}
      </p>
    </footer>
  )
}

export default Footer
